import React from "react"

import Layout from "@src/components/Layout"
import Row from "@src/layouts/V2/components/Row/Row"
import Button from "@src/components/Button"
import getAppUrl from "@src/utils/getAppUrl"
import VideoRow from "@src/components/VideoRow/VideoRow"
import DotBG from "@src/layouts/V2/components/DotBG/DotBG"
import ImageRow from "@src/layouts/V2/Features/components/ImageRow/ImageRow"
import CalloutWithDemoForm from "@src/components/CalloutWithDemoForm/CalloutWithDemoForm"
import { useBreakpoint } from "gatsby-plugin-breakpoints"

import Video from "@src/videos/ecommerce.mp4"
import SectionOne from "@src/icons/industries/ecom_section_one.png"
import CardOne from "@src/icons/industries/ecom_card_one.png"
import CardTwo from "@src/icons/industries/ecom_card_two.png"
import CardThree from "@src/icons/industries/ecom_card_three.png"
import CardFour from "@src/icons/industries/ecom_card_four.png"

import OG from "@src/icons/og/OG_Ecommerce.jpg"
import * as styles from "./Ecommerce.module.scss"
import Column from "../../V2/components/Column/Column"

const Ecommerce = () => {
  const appUrl = getAppUrl.hook()
  const breakpoints = useBreakpoint()
  return (
    <Layout
      title="Simplify Your Ecommerce Operation"
      description="Manage Your Online Stores with low code internal tools - Focus on Business Growth Instead!"
      url="https://canonic.dev/industries/ecommerce"
      ogImage={OG}
      className={styles.ecom}
    >
      <Row>
        <VideoRow
          title="Simplify Your Ecommerce Operation"
          description={
            <p className={styles.ecomDescription}>
              Manage Your Online Stores with low code internal tools - Focus on
              Business Growth Instead!
            </p>
          }
          button={[
            <a href={"#get-a-demo"}>
              <Button isPrimary className={styles.ecomCtaFilled}>
                Get a Demo
              </Button>
            </a>,
            <a href={appUrl} target="_blank" rel="noopener noreferrer">
              <Button className={styles.ecomCtaOutline}>Try for free</Button>
            </a>,
          ]}
          videoUrl={Video}
          className={styles.ecom}
        />
      </Row>
      <section>
        <Row>
          <ImageRow
            title="Managing & maintaining your 
              e-commerce business can be a daunting task."
            subtitle={
              <>
                <p>
                  From manual data entry to maintaining consistent product
                  information across multiple platforms like Amazon, eBay, etc.
                  can be overwhelming to manage it all. Inconsistent product
                  information across platforms can lead to customer confusion
                  like conflicting prices, inconsistent product descriptions,
                  different availability statuses, etc.
                </p>
                <p>
                  Payment gateways, shipping providers, marketing tools, and
                  payment gateways are often not integrated with e-commerce
                  businesses, making the process tiresome.
                </p>
                <p>
                  It may be difficult for e-commerce businesses to track their
                  inventory accurately, especially if they have multiple sales
                  channels or warehouses, leading to stockouts, overselling, and
                  inaccurate reporting.
                </p>
              </>
            }
            icon={SectionOne}
            className={styles.ecomSolution}
            reverse
            borderless
          />
        </Row>
      </section>
      <Row>
        <h1 className={styles.ecomUseCasesTitle}>
          Solve Your Manufacturing Challenges with Low-Code Custom Solutions.
        </h1>
        <Column.Wrap>
          <Column
            title="Refund & Exchange Management Tool"
            icon={CardOne}
            description={
              <>
                Centralise all the refund and exchange requests to ensure a
                seamless & efficient experience.{" "}
                <span className={styles.heavyFont}>
                  Manage inventory checks by using data from Shopify.
                </span>
              </>
            }
          />
          <Column
            title="Out-of-Stock Order Management Solution"
            icon={CardTwo}
            description={
              <>
                Monitor inventory levels & update the order status in real-time.
                Quickly respond to out-of-stock orders, keeping customers
                informed & reducing the{" "}
                <span className={styles.heavyFont}>risk of lost sales</span>
              </>
            }
          />
          <Column
            title="Partner Catalogue & Inventory Management"
            icon={CardThree}
            description={
              <>
                Monitor inventory levels & update the order status in real-time.
                Quickly respond to out-of-stock orders, keeping customers
                informed & reducing the{" "}
                <span className={styles.heavyFont}>risk of lost sales</span>
              </>
            }
          />
        </Column.Wrap>
      </Row>
      <CalloutWithDemoForm originPage="Ecommerce" id={"get-a-demo"} />
    </Layout>
  )
}

export default Ecommerce
