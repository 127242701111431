import React from "react"

import * as styles from "./VideoRow.module.scss"

const VideoRow = ({ title, description, button, videoUrl, className }) => {
  return (
    <section className={`${styles.row} ${className}`}>
      <div className={styles.rowContent}>
        <h1 className={styles.rowContentHeading}>{title}</h1>
        <p className={styles.rowContentDescription}>{description}</p>
        <div className={styles.rowButtons}>{button}</div>
      </div>
      <div className={styles.rowVideo}>
        <video autoPlay={true} playsInline muted loop src={videoUrl} />
      </div>
    </section>
  )
}

export default VideoRow
